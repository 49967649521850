const UserIcon = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 2550 3300"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
    >
      <circle
        cx="1299.8"
        cy="1201.95"
        r="474.499"
        transform="translate(-622.798 -744.876) scale(1.46187)"
      />
      <path d="M-.326 3301.912c0-704.051 571.604-1275.659 1275.662-1275.659 704.051 0 1275.658 571.608 1275.658 1275.659" />
    </svg>
  )
}

export default UserIcon
